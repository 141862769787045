const containsEmail = (input = '') => {
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
    return emailRegex.test(input);
};

export const validateInput = (input = '') => {
    // starting values
    let validations = {
        required: false,
        containsPii: false,
    };

    if (input.length === 0) {
        validations.required = true;
    }

    if (containsEmail(input)) {
        validations.containsPii = true;
    }

    return validations;
};

const MAX_LENGTH = 250; // this should be plenty, not expecting a novel
export const sanitizeInput = (input = '') => input.trim().slice(0, MAX_LENGTH);
