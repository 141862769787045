import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
    Button,
    Modal,
    SpaceBetween,
    Box,
    Input,
    FormField,
} from '@amzn/awsui-components-react';

import messages from './index.messages';
import { recordMetrics } from '../utils/analytics';
import { setWindowSearch } from '../utils/urls';
import { sanitizeInput, validateInput } from './utils';

const legacySearch = 'legacy=1';

const LegacyLabButton = () => {
    const [modalOpen, modalOpenSet] = useState(false);
    const [fallbackReason, fallbackReasonSet] = useState('');
    const [shownError, shownErrorSet] = useState(null);
    const [isSubmitting, isSubmittingSet] = useState(false);
    const { formatMessage } = useIntl();
    const location = useLocation();

    const onCloseClick = () => modalOpenSet(false);

    const onReasonChange = event => {
        fallbackReasonSet(event.detail.value);
        shownErrorSet(null);
    };

    const onConfirmClick = async () => {
        if (isSubmitting) return;
        const { required, containsPii } = validateInput(fallbackReason);
        if (required) {
            shownErrorSet(formatMessage(messages.requiredInputError));
            return;
        } else if (containsPii) {
            shownErrorSet(formatMessage(messages.piiInputError));
            return;
        }
        isSubmittingSet(true);
        try {
            // record the reason
            await recordMetrics('eh-migration', 'AppMetrics', {
                metrics: {
                    fallbackReason: 1,
                    providedValue: sanitizeInput(fallbackReason),
                },
            });
        } catch (error) {
            console.error(error);
        } finally {
            const search =
                location.search.length === 0
                    ? legacySearch
                    : `${location.search}&${legacySearch}`;
            setWindowSearch(search);
            isSubmittingSet(false);
        }
    };

    const isLegacyEnabled = location.search.includes(legacySearch);

    return isLegacyEnabled ? null : (
        <Fragment>
            <Button onClick={() => modalOpenSet(true)} formAction="none">
                {formatMessage(messages.legacyLabButton)}
            </Button>
            <Modal
                visible={modalOpen}
                header={formatMessage(messages.legacyLabModalTitle)}
                closeAriaLabel={formatMessage(messages.legacyLabModalClose)}
                onDismiss={onCloseClick}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                onClick={onCloseClick}
                                formAction="none"
                                variant="link"
                            >
                                {formatMessage(messages.legacyLabModalClose)}
                            </Button>
                            <Button
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                onClick={onConfirmClick}
                                variant="primary"
                            >
                                {formatMessage(messages.legacyLabModalConfirm)}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <>
                    <Box variant="p">
                        {formatMessage(messages.legacyLabModalContent)}
                    </Box>
                    <FormField
                        description={formatMessage(
                            messages.fallbackInputDescription
                        )}
                        constraintText={formatMessage(
                            messages.fallbackInputConstraint
                        )}
                        label={formatMessage(messages.fallbackInputLabel)}
                        errorText={shownError}
                    >
                        <Input
                            value={fallbackReason}
                            required
                            data-testid="fallback-reason-input"
                            onChange={onReasonChange}
                        />
                    </FormField>
                </>
            </Modal>
        </Fragment>
    );
};

export default LegacyLabButton;
