import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    legacyLabButton: {
        id: 'legacyLab.button',
        defaultMessage: 'Lab issues?',
    },
    legacyLabModalTitle: {
        id: 'legacyLab.modal.title',
        defaultMessage: 'Use legacy lab experience',
    },
    legacyLabModalContent: {
        id: 'legacyLab.modal.content',
        defaultMessage:
            'If you are experiencing problems accessing or using the labs, you can try reverting back to the legacy lab experience by confirming this message and clicking the lab again.',
    },
    legacyLabModalConfirm: {
        id: 'legacyLab.modal.confirm',
        defaultMessage: 'Confirm',
    },
    legacyLabModalClose: {
        id: 'legacyLab.modal.close',
        defaultMessage: 'Close',
    },
    fallbackInputDescription: {
        id: 'legacyLab.modal.fallbackInputDescription',
        defaultMessage:
            'This helps us understand and resolve reasons for doing this.',
    },
    fallbackInputConstraint: {
        id: 'legacyLab.modal.fallbackInputConstraint',
        defaultMessage:
            'Do not include any personally identifiable information (PII).',
    },
    fallbackInputLabel: {
        id: 'legacyLab.modal.fallbackInputLabel',
        defaultMessage: 'Fill out a reason',
    },
    requiredInputError: {
        id: 'legacyLab.modal.requiredInputError',
        defaultMessage: 'Required',
    },
    piiInputError: {
        id: 'legacyLab.modal.piiInputError',
        defaultMessage:
            'Please do not include any personally identifiable information (PII) in the reason.',
    },
});

export default messages;
